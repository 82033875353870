import React, { useState } from "react";
import Login from "../images/login.png";
import { CgProfile } from "react-icons/cg";
import { CiLock, CiUnlock } from "react-icons/ci";
import { motion } from "framer-motion";
import { fadeIn } from "../Varient";
import { Link } from "react-router-dom";
import './chat.css';
const Chat = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <div
      className="bg-cover bg-center bg-repeat max-md:h-[750px]"
      style={{ backgroundImage: `url(${Login})` }}
    >
      <div className="pt-28 text-center">
        <motion.h2
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="pb-4 font-kurdish_042 text-2xl text-white"
        >
          تائێستە خۆت تۆمارنەکردوە؟
        </motion.h2>
        <motion.button
          variants={fadeIn("left", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="text-md btn w-[120px] rounded-[48.5px] border bg-white text-center align-middle font-kurdish_043 text-primary-100"
        >
          خۆت تۆمابکە
        </motion.button>
      </div>
      <div className="mt-6 flex h-[560px] flex-col items-center justify-center space-y-8">
        <div className="flex justify-center">
          <motion.h2
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="custom-divider w-80 text-center font-kurdish_042 text-4xl text-primary-200"
          >
            بەخێر بێیت
          </motion.h2>
        </div>
        {/* ناوی بەکارهێنەر */}
        <motion.div
          variants={fadeIn("left", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="relative flex justify-center"
        >
          <input
            type="text"
            className="h-14 w-80 rounded-[48.5px] bg-gradient-to-r from-primary-100
           via-[#9b93d2] to-primary-200 text-center font-kurdish_042 text-white outline-none focus:outline-none"
            placeholder="ناوی بەکار هێنەر"
          />
          <CgProfile
            size={30}
            className="pointer-events-none absolute right-2 top-[50%] -translate-y-1/2 transform stroke-current font-thin text-white opacity-60"
          />
        </motion.div>
        {/* پاسۆردی بەکارهێنەر */}
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="relative flex justify-center"
        >
          <input
            type={showPassword ? "text" : "password"}
            className="h-14 w-80 rounded-[48.5px] bg-transparent bg-gradient-to-r
           from-primary-100 via-[#9b93d2] to-primary-200 text-center font-kurdish_042  text-white caret-white outline-none focus:outline-none"
            placeholder="وشەی نهێنی"
          />
          {showPassword ? (
            <CiUnlock
              size={30}
              className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
              onClick={togglePassword}
            />
          ) : (
            <CiLock
              size={30}
              className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
              onClick={togglePassword}
            />
          )}
        </motion.div>
        <div className="flex justify-center">
          <motion.button
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="btn w-[120px] rounded-[48.5px] items-end bg-gradient-to-r from-primary-200 to-primary-100 
            pb-2 font-kurdish_043 text-lg font-thin text-white"
          >
            داخڵ ببە
          </motion.button>
        </div>
        <motion.div
        
        variants={fadeIn("right", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="flex justify-center">
          <Link
            to="/location"
            className="font-kurdish_041 text-primary-100"
            href="#"
          >
            وشەی نهێنیت لەبیر کردووە؟
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Chat;
